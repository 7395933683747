export enum Slug {
  HOME = "/",
  ABOUT_US = "/about-us",
  COMPARE = "/compare-projects",
  CONTACT_US = "/contact-us",
  LATEST_MORTGAGE_RATES = "/latest-mortgage-rates",
  LATEST_PROMOTIONS = "/latest-promotion",
  MORTGAGE_CALCULATOR = "/mortgage-calculator",
  PRIVACY_POLICY = "/privacy-policy",
  PROJECTS = "/projects",
  PROMOTION = "/promotion",
  TOP_TOP_SOON = "/top-soon",
  UPCOMING_LAUNCHES = "/upcoming-launches",
  VIRTUAL_TOURS = "/virtual-tours",
  PROJECT = "/project",
  PROPERTY = "/property",
  PARTNER_US = "/partner-us",
  RCR = "/rcr",
  CCR = "/ccr",
  OCR = "/ocr",
  NOT_FOUND = "/404",
  PRICE_LIST = "/price-list",
  SECTIONS = "/sections",
  LAYOUT = "/layouts",
  FEATURED_LAUNCHES = "/featured-launches",
}
